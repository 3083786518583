import React, { useState, useEffect } from 'react';
import './ClickReport.css';
import { getEmailTracking } from '../../../api/auth';
import DownloadCSV from '../../other/DownloadCSV';

const ClickReport = () => {
  const [filter, setFilter] = useState('last-clicked-emails');
  const [clickReports, setClickReports] = useState([]);
  const [downloadUrl, setDownloadUrl] =  useState(process.env.REACT_APP_API_URL  + '/email-tracking/download?type=click&filter=last-clicked-emails');
  const fileName = 'click-report.csv';

  useEffect(() => {
    filterEmailTracking("clicked");
  }, []);

  const filterEmailTracking = (value) => {
    setFilter(value);
    setDownloadUrl(process.env.REACT_APP_API_URL  + '/email-tracking/download?type=click&filter=' + value);
    getEmailTracking("click", value).then((response) => {
        setClickReports(response.email_trackings);
    })
  }

  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `Sent on ${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}, ${timePart.slice(0, 5)}`;
  };

  return (
    <div className="email-tracking">
      <h1>Click Report</h1>
      <div className="controls">
        <div className="select-wrapper">
          <select value={filter} onChange={(e) => filterEmailTracking(e.target.value)}>
            <option value='all'>All emails</option>
            <option value='unclicked'>Unclicked emails</option>
            <option value="clicked">Last clicked emails</option>
          </select>
        </div>
        <DownloadCSV url={downloadUrl} fileName={fileName} />
      </div>
      <table>
        <thead>
          <tr>
            <th>RECIPIENTS</th>
            <th>EMAIL</th>
            <th>ACTIVITY</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {clickReports.map((email) => (
            <tr key={email.id}>
              <td>
                {email.recipients.map((recipient, index) => (
                  <div key={index} className="recipient">{recipient.email}</div>
                ))}
              </td>
              <td>
                <div className="email-subject">{email.email_subject}</div>
                <div className="sent-date">Sent on {formatDate(email.send_date)}</div>
              </td>
              <td>
                <div className="clicks">{email.clicks_count} {email.clicks_count === 1 ? 'click' : 'clicks'}</div>
                {email.clicks_count > 0 && <div className="first-click">First click on {formatDate(email.first_email_activity?.created_at)}</div>}
              </td>
              <td>
                <button className="action-button">...</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClickReport;