import React, { useState, useEffect } from 'react';
import './EmailTracking.css';
import { getEmailTracking } from '../../../api/auth';
import DownloadCSV from '../../other/DownloadCSV';

const EmailTracking = ({contact}) => {
  const [filter, setFilter] = useState('last-opened-emails');
  const [emailTrackings, setEmailTrackings] = useState([]);
  const [downloadUrl, setDownloadUrl] =  useState(process.env.REACT_APP_API_URL  + '/email-tracking/download?type=open&filter=last-opened-emails');
  const fileName = 'email-tracking.csv';

  useEffect(() => {
    filterEmailTracking("opened", contact);
  }, [contact]);

  const filterEmailTracking = (value, contact = null) => {
    setFilter(value);
    setDownloadUrl(process.env.REACT_APP_API_URL  + '/email-tracking/download?type=open&filter=' + value);
    getEmailTracking("open", value, contact?.id).then((response) => {
      setEmailTrackings(response.email_trackings);
    })
  }

  const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `Sent on ${months[parseInt(month) - 1]} ${parseInt(day)}, ${year}, ${timePart.slice(0, 5)}`;
  };

  return (
    <div className="email-tracking">
      {!contact && <h1>Email Tracking</h1>}
      <div className="controls">
        <div className="select-wrapper">
          <select value={filter} onChange={(e) => filterEmailTracking(e.target.value)}>
            <option value='all'>All emails</option>
            <option value='unopened'>Unopened emails</option>
            <option value="opened">Last opened emails</option>
          </select>
        </div>
        <DownloadCSV url={downloadUrl} fileName={fileName} />
      </div>
      <table>
        <thead>
          <tr>
            <th>RECIPIENTS</th>
            <th>EMAIL</th>
            <th>ACTIVITY</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {emailTrackings.map((email) => (
            <tr key={email.id}>
              <td>
                {email.recipients.map((recipient, index) => (
                  <div key={index} className="recipient">{recipient.email}</div>
                ))}
              </td>
              <td>
                <div className="email-subject">{email.email_subject}</div>
                <div className="sent-date">Sent on {formatDate(email.send_date)}</div>
              </td>
              <td>
                <div className="opens">{email.opens_count} {email.opens_count === 1 ? 'open' : 'opens'}</div>
                {email.opens_count > 0 && <div className="first-open">First open on {formatDate(email.first_email_activity?.created_at)}</div>}
              </td>
              <td>
                <button className="action-button">...</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmailTracking;