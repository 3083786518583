import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import './EmailProductivity.css';
import { getProductivity } from '../../../api/auth';

// Custom Card components
const Card = ({ children, className = '' }) => (
    <div className={`bg-white rounded-lg shadow-md p-4 ${className}`}>
        {children}
    </div>
);

const CardHeader = ({ children }) => (
    <h3 className="text-lg font-semibold mb-2">{children}</h3>
);

const CardContent = ({ children }) => (
    <div>{children}</div>
);

const EmailProductivity = () => {

    const getStartAndEndDate = () => {
        const today = new Date();

        // Function to get last month's start date
        const getLastMonthStartDate = () => {
            const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Start of last month
            return lastMonthStart.toISOString().split('T')[0];
        };

        // Function to get last month's end date
        const getLastMonthEndDate = () => {
            const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0); // End of last month
            return lastMonthEnd.toISOString().split('T')[0];
        };

        // Function to get last 7 days' start date
        const getLast7DaysStartDate = () => {
            const last7DaysStart = new Date(today);
            last7DaysStart.setDate(today.getDate() - 6); // 7 days including today
            return last7DaysStart.toISOString().split('T')[0];
        };

        // Function to get last 7 days' end date
        const getLast7DaysEndDate = () => {
            const last7DaysEnd = new Date(today); // End date is today
            return last7DaysEnd.toISOString().split('T')[0];
        };

        return {
            lastMonthStart: getLastMonthStartDate(),
            lastMonthEnd: getLastMonthEndDate(),
            last7DaysStart: getLast7DaysStartDate(),
            last7DaysEnd: getLast7DaysEndDate(),
        };
    };

    const {
        lastMonthStart,
        lastMonthEnd,
        last7DaysStart,
        last7DaysEnd,
    } = getStartAndEndDate();

    const [timeInterval, setTimeInterval] = useState("week");
    const [emailProductivity, setEmailProductivity] = useState({});

    useEffect(() => {
        const from = "week" === timeInterval ? last7DaysStart : lastMonthStart;
        const to = "week" === timeInterval ? last7DaysEnd : lastMonthEnd;
        getProductivity(from, to).then((response) => {
            setEmailProductivity(response);
        })
    }, [timeInterval, last7DaysStart, last7DaysEnd, lastMonthStart, lastMonthEnd]);

    const emailSentOptions = {
        chart: {
            type: 'line',
            height: 200,
            toolbar: { show: false },
        },
        xaxis: {
            type: 'category',
        },
        yaxis: {
            title: { text: 'Emails Sent' },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ['#00897b'],
    };

    const timeUntilFirstOpenOptions = {
        chart: {
            type: 'bar',
            height: 200,
            toolbar: { show: false },
        },
        xaxis: {
            type: 'category',
        },
        yaxis: {
            title: { text: 'Count' },
        },
        colors: ['#00897b'],
    };

    const weeklyScheduleOptions = {
        chart: {
            type: 'heatmap',
            height: 250,
            toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        colors: ['#e0f2f1', '#80cbc4', '#26a69a', '#00897b'],
        xaxis: {
            type: 'category',
            categories: Array.from({ length: 24 }, (_, i) => `${i}:00`), // 24-hour format
            position: 'top',
        },
        yaxis: {
            categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            reversed: true,
        },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0.5,
                colorScale: {
                    ranges: [
                        { from: 0, to: 0, color: '#e0f2f1', name: 'No emails' },
                        { from: 1, to: 1, color: '#80cbc4', name: '1 email' },
                        { from: 2, to: 2, color: '#26a69a', name: '2 emails' },
                        { from: 3, to: 3, color: '#00897b', name: '3 emails' },
                    ],
                },
            },
        },
    };

    const openingRateOptions = {
        chart: {
            type: 'radialBar',
            height: 300,
            toolbar: { show: false },
        },
        plotOptions: {
            radialBar: {
                hollow: { size: '60%' },
                dataLabels: {
                    name: { show: false },
                    value: { fontSize: '22px', offsetY: -10 },
                },
            },
        },
        colors: ['#FFB300'], // Yellow for 40% progress
        series: [40],
    };

    // Rest of the chart data...

    return (
        <div className="space-y-4">
            {/* Top section with stats and date selector */}
            <div className="email-productivity-header">
                <div className="stats-container">
                    <div className="stats-item">
                        <h4>Sent Emails</h4>
                        <div className="count">{emailProductivity.total_sent_email}</div>
                        {/* <div className="percentage">↑ +100%</div> */}
                    </div>
                    <div className="stats-item">
                        <h4>Recipients</h4>
                        <div className="count">{emailProductivity.total_recipients}</div>
                        {/* <div className="percentage">↑ +100%</div> */}
                    </div>
                </div>
                <div className="date-selector">
                    <select onChange={(e) => setTimeInterval(e.target.value)}>
                        <option value="week">Last week ({last7DaysStart} - {last7DaysEnd})</option>
                        <option value="month">Last month ({lastMonthStart} - {lastMonthEnd})</option>
                    </select>
                </div>
            </div>

            {emailProductivity.email_tracking_weekday_hourly && <Card>
                <CardHeader>When you send your emails</CardHeader>
                <CardContent>
                    <ReactApexChart
                        options={weeklyScheduleOptions}
                        series={emailProductivity.email_tracking_weekday_hourly}
                        type="heatmap"
                        height={250}
                    />
                </CardContent>
            </Card>}

            <div className="email-productivity-row">
                {emailProductivity.email_tracking_daily_count && <Card className="card-item">
                    <CardHeader>Emails sent by day</CardHeader>
                    <CardContent>
                        <ReactApexChart
                            options={emailSentOptions}
                            series={[{ name: 'Emails', data: emailProductivity.email_tracking_daily_count }]}
                            type="line"
                            height={200}
                        />
                    </CardContent>
                </Card>}
                {emailProductivity.email_tracking_open_count && <Card className="card-item">
                    <CardHeader>Time until first open</CardHeader>
                    <CardContent>
                        <ReactApexChart
                            options={timeUntilFirstOpenOptions}
                            series={[{ name: 'Count', data: emailProductivity.email_tracking_open_count }]}
                            type="bar"
                            height={200}
                        />
                    </CardContent>
                </Card>}
            </div>

            {/* Row of stats */}
            <div className="email-productivity-row grid grid-cols-1 md:grid-cols-3 gap-4">
                {emailProductivity && <Card className="card-item">
                    <CardHeader>Opening rate</CardHeader>
                    <CardContent>
                        <ReactApexChart
                            options={openingRateOptions}
                            series={[emailProductivity.total_sent_email > 0 ? (emailProductivity.unique_open_email / emailProductivity.total_sent_email) * 100 : 0]}
                            type="radialBar"
                            height={200}
                        />
                    </CardContent>
                </Card>}
                {emailProductivity && <Card className="card-item">
                    <CardHeader>Link clicked rate</CardHeader>
                    <CardContent>
                        <ReactApexChart
                            options={openingRateOptions}
                            series={[emailProductivity.total_email_links > 0 ? (emailProductivity.total_email_clicks / emailProductivity.total_email_links) * 100 : 0]}
                            type="radialBar"
                            height={200}
                        />
                    </CardContent>
                </Card>}
            </div>

            {/* <div className="grid grid-cols-3 gap-4">
                <Card>
                    <CardHeader>Your average response time</CardHeader>
                    <CardContent>
                        <div className="text-gray-500">No data for this period</div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>Time taken to respond</CardHeader>
                    <CardContent>
                        <div className="text-gray-500">No data for this period</div>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader>Time taken for first response</CardHeader>
                    <CardContent>
                        <div className="text-gray-500">No data for this period</div>
                    </CardContent>
                </Card>
            </div> */}
        </div>
    );
};

export default EmailProductivity;