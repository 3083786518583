import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { subscribe, unsubscribe } from '../../api/auth';

const Unsubscribe = () => {
  const { uuid } = useParams(); // Get the UUID from the URL
  const [unsubscribed, setUnsubscribed] = useState(false); // Track if user has unsubscribed
  const [error, setError] = useState(''); // Track any errors

  const handleUnsubscribe = async () => {
    unsubscribe(uuid).then((response) => {
        setUnsubscribed(true);
    });
  };

  const handleSubscribe = async () => {
    subscribe(uuid).then((response) => {
        setUnsubscribed(false);
    });
  };

  if (unsubscribed) {
    // Show the success UI as seen in the uploaded image
    return (
      <div className="unsubscribe-container">
        <div className="success-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zM6.293 10.293a1 1 0 0 1 1.414 0l3-3a1 1 0 0 1 1.414 1.414l-3.707 3.707a1 1 0 0 1-1.414 0L4.879 8.707a1 1 0 0 1 1.414-1.414l1 1z"/>
          </svg>
        </div>
        <h3>Unsubscribe Successful</h3>
        <p>
          You will not receive any more emails from this sender through Mailsuite.
        </p>
        <p>
          Did you unsubscribe by mistake? <Link to="#" onClick={handleSubscribe}>Resubscribe</Link>
        </p>
      </div>
    );
  }

  return (
    <div className="unsubscribe-container">
      <h3>Unsubscribe</h3>
      <p>Are you sure you'd like to stop receiving emails from this sender?</p>
      {error && <p className="error-message">{error}</p>}
      <button className="unsubscribe-btn" onClick={handleUnsubscribe}>
        Unsubscribe from this sender
      </button>
    </div>
  );
};

export default Unsubscribe;