import React, { useState } from 'react';

const DeleteAccountModal = ({ isOpen, onClose, onConfirm }) => {
    const [isChecked, setIsChecked] = useState(false);

    if (!isOpen) {
        return null;
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <div className="modal-header">
                    <span className="modal-icon">❌</span>
                    <h2>Delete your account and all data</h2>
                </div>
                <div className="modal-body">
                    <p className="warning-text">WARNING:</p>
                    <p>If you delete your account, you will lose all data related to your TrackEmail account.</p>
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        I understand I will lose all data related to my TrackEmail account.
                    </label>
                </div>
                <div className="modal-footer">
                    <button
                        className="confirm-button"
                        onClick={onConfirm}
                        disabled={!isChecked}
                    >
                        Yes, delete my account
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        No, not now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DeleteAccountModal;