import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Create a custom axios instance
const api = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const loginUser = async (email, password) => {
  const response = await api.post('/login', { email, password });
  return response.data;
};

export const registerUser = async (name, email, password) => {
  const response = await api.post('/register', { name, email, password });
  return response.data;
};

export const logoutUser = async () => {
  const response = await api.post('/logout');
  return response.data;
};

export const getCurrentUser = async () => {
  const response = await api.get('/users/me');
  return response.data;
};

export const getEmailTracking = async (type = "open", filter = "all", contact_id = null, page = 1, limit = 10) => {
  const params = {
    page: page,
    limit: limit,
    type: type,
    filter: filter
  };
  if (contact_id) {
    params.contact_id = contact_id;
  }
  const response = await api.get(`/email-tracking`, { params: params });
  return response.data;
};

export const getContacts = async (page = 1, limit = 10, q = null, unsubscribed = false) => {
  const params = {
    page: page,
    limit: limit,
  };
  if (q != null) {
    params.q = q;
  }
  if (unsubscribed) {
    params.unsubscribed = unsubscribed;
  }
  const response = await api.get(`/contacts`, { params: params });
  return response.data;
};

export const updateContact = async (contact) => {
  const response = await api.put('/contacts/' + contact.id, contact);
  return response.data;
};

export const saveSetting = async (user_id, setting) => {
  const response = await api.post('/settings/' + user_id, setting);
  return response.data;
};

export const getSetting = async (user_id) => {
  const response = await api.get('/settings/' + user_id);
  return response.data;
};

export const saveNotificationSetting = async (user_id, setting) => {
  const response = await api.post('/notification-settings/' + user_id, setting);
  return response.data;
};

export const getNotificationSetting = async (user_id) => {
  const response = await api.get('/notification-settings/' + user_id);
  return response.data;
};

export const unsubscribe = async (uuid) => {
  const response = await api.get('/unsubscribe/' + uuid);
  return response.data;
};

export const subscribe = async (uuid) => {
  const response = await api.get('/subscribe/' + uuid);
  return response.data;
};

export const getProductivity = async (from, to) => {
  const response = await api.get(`/email-productivity?from=${from}&to=${to}`);
  return response.data;
};

export const updateUser = async (user_id, user) => {
  const response = await api.put('/users/' + user_id, user);
  return response.data;
};

export const deleteUser = async (user_id) => {
  const response = await api.delete('/users/' + user_id);
  return response.data;
};

export default api;